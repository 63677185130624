import React, { useState, useEffect } from 'react'
import * as styles from '../assets/styles.module.scss'
import Header from '../components/header'
import Footer from '../components/footer'

import BookForm from '../components/BookForm'

const Model = () => {
    const [component, setComponent] = useState(<BookForm />)

    return(
        <section className={styles.Container}>
            <Header />
            <div className={styles.Book}>
                <section className={styles.Hero}>
                    <img src={`/images/white-babydoll.jpg`} />
                </section>
                <section className={styles.Centered}>
                    {component}
                </section>
            </div>
            <Footer />
        </section>
    )
}

export default Model