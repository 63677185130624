import React, { useEffect, useState } from 'react'
import * as styles from '../assets/styles.module.scss'
import Thanks from '../components/Thanks'

import ApiService from '../data/services/ApiService'

import {
    Form,
    Button,
    Row,
    Col,
    Input,
    FormGroup,
    Label
} from 'reactstrap'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const BookForm = () => {
    const [component, setComponent] = useState(false)
    const [models, setModels] = useState([])
    const [model, setModel] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [amount] = useState(450)

    useEffect(() => {
        (async () => {
            const response = await ApiService.getModels()
            let data: any = []
            await response.data.models.map((item: any) => {
                return data.push({
                    value: item.id,
                    label: item.name,
                })
            })

            setModels(data)
        })()
    }, [])

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        if (!_validateForm()) {
            toastr.error('No olvides llenar los campos.', 'Error')

            return
        }

        const data = {
            modelId: model,
            name,
            email,
            phone,
            amount
        }

        await ApiService.reserveCall(data).catch(error => {
            toastr.error(`I couldn't book your call, please try again.`, 'Error')
        })

        const c: any = _renderThanks()
        setComponent(c)
    }

    const _validateForm = () => {
        if (name === '') { return false }
        if (email === '') { return false }
        if (phone === '') { return false }
        if (model === '') { return false }

        return true
    }

    const _renderForm = () => {
        return (
            <Form id="create" method="POST" onSubmit={ handleSubmit }>
                <h1>Reserve a Spot</h1>

                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Label>
                                Model *
                            </Label>
                            <select className="custom-select"
                                defaultValue={model}
                                onChange={(event) => setModel(event.target.value)}>
                                <option key="0" value="0">Select a model</option> 
                                {models.map((item: any, i: number) => {
                                    return (
                                        <option key={i} value={item.value}>{item.label}</option>
                                    )
                                })}
                            </select>
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                Full Name *
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                required
                                onChange={event => setName(event.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                Email *
                            </Label>
                            <Input
                                type="email"
                                className="form-control"
                                required
                                onChange={event => setEmail(event.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>
                                Phone Number *
                            </Label>
                            <Input
                                type="tel"
                                className="form-control"
                                required
                                onChange={event => setPhone(event.target.value)}
                            />
                        </FormGroup>

                        <div className={styles.Price}>
                            Total: $ {amount}
                        </div>

                        <div className={styles.Disclaimer}>
                            By completing this transaction you certify that you are 18 years or older,
                            agree to the Terms and Conditions of this purchase, and have read our Privacy Policy.
                        </div>

                        <Button
                            type="submit"
                            className={styles.ButtonPrimary}
                        >
                            <i className={`mdi mdi-check`}></i> Confirm Booking
                        </Button>
                    </Col>
                </Row>
            </Form>
        )
    }

    const _renderThanks = () => {
        return(<Thanks name={name} email={email} amount={amount} model={model} />)
    }

    return(
        <React.Fragment>
            {!component && (_renderForm())}
            {component}
        </React.Fragment>
    )
}

export default BookForm