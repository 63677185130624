import React from 'react'
import * as styles from '../assets/styles.module.scss'

interface Props {
    name: string
    email: string
    amount: number
    model: string
}

const Thanks = (props: Props) => {
    // return(
    //     <section className={styles.Thanks}>
    //         <h1>¡Gracias!</h1>

    //         <h2>Completa tu reservación</h2>

    //         <p><strong>Nombre:</strong> {props.name}</p>
    //         <p><strong>Estatus:</strong> Waiting for Bank Transfer</p>

    //         <p>
    //             Gracias por reservar una llamada con nosotros,
    //             por favor realiza un depósito o transferencia a la siguiente cuenta:
    //         </p>

    //         <p>
    //             <strong>Banco: </strong> CitiBanamex <br />
    //             <strong>Cuenta: </strong> 5204 1673 6433 2395
    //         </p>

    //         <p>
    //             Una vez realizado el depósito, envíanos el comprobante a pagos@call-ladies.com junto con tu nombre y correo electrónico.
    //         </p>

    //         <p>
    //             Ya que confirmemos el pago, te enviaremos el link para tu llamada con {props.model}
    //         </p>
    //     </section>
    // )
    return(
        <section className={styles.Thanks}>
            <h1>Thanks!</h1>

            <h2>Complete your reservation</h2>

            <p><strong>Name:</strong> {props.name}</p>
            <p><strong>Status:</strong> Waiting for Bank Transfer</p>

            <p>
                Thank you for booking with us,
                please complete your payment to the following account:
            </p>

            <p>
                <strong>Bank: </strong> CitiBanamex <br />
                <strong>Account: </strong> 1111 2222 3333 4444
            </p>

            <p>
                Once you've completed your payment,
                please send us the receipt along your name and email to <strong>payments@call-ladies.com</strong>.
            </p>

            <p>
                Once we confirm your payment, we'll send you an email with the details for your call.
            </p>
        </section>
    )
}

export default Thanks